export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    //   '.navItem a': {
    //     ':hover': {
    //       color: 'primary',
    //     },
    //   },
    // },
    // '.navItem': {
    //   ':hover': {
    //     color: 'primary',
    //   },
  },

  footer: {
    // borderTop: '2px solid',
    // borderColor: 'primary',
    // '.address': {
    //   display: 'none',
    // },
    backgroundColor: 'black',
    '.columnLogo': {
      '.image': {
        filter: 'brightness(0) invert(1)'
      }
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['3rem', '', '4rem'],
    textTransform: 'uppercase',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '1.5rem',
    color: 'white'
  },
  subtitle: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '', '2rem'],
    marginBottom: '1.5rem',
    backgroundColor: 'white',
    textAlign: 'center',
    padding: '1rem 7.5%',
    color: 'background',
    clipPath: 'polygon(100% 0, 95% 50%, 100% 100%, 0 100%, 5% 50%, 0 0)',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    '::before': {
      content: "'•'",
      padding: '0rem 1rem'
    },
    '::after': {
      content: "'•'",
      padding: '0rem 1rem'
    },
    boxShadow: '0px 0px 10px black'
  },
  text: {
    textAlign: 'center',
    p: {
      textAlign: 'center',
      fontSize: 'inherit'
    }
  },

  sideBySide1: {
    padding: '1rem',
    background: 'transparent',
    '.lazyload-wrapper': {
      img: {
        maxHeight: '75vh'
      },
      position: 'relative',
      '::before': {
        content: "''",
        width: '100%',
        height: '100%',
        background: 'radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(37, 37, 37,1) 75%)',
        position: 'absolute',
        left: '0rem'
      }
    },
    '.content': {
      alignItems: 'center'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text'
    },

    '.section': {
      '> div:nth-of-type(1)': {
        order: '2'
      }
    }
  },

  homepageHeroShout: {},

  homepageShout: {
    backgroundColor: 'background',
    '.title': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black']
    },
    '.text': {
      fontFamily: 'display',
      fontSize: ['1.6rem', '', '2rem'],
      order: ['4', '', '3'],
      border: ['', '', 'solid 9px'],
      borderColor: ['', '', 'black'],
      fontFamily: 'display',
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['black', '', '', '#3e330a']
    },
    '.date': {
      backgroundColor: '#202020',
      border: ['', '', '', '#ffffff solid 9px'],
      borderColor: ['', '', '', 'black'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      order: ['', '', '4']
    },
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvent: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1'
  },

  homepageBoxes: {
    '.box': {
      width: ['calc(50% - 1rem)', '', 'calc(33.3% - 1rem)'],
      '.image': {
        height: '150px',
        objectFit: 'contain'
      },
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['1.25rem', '1.5rem', '1.5rem', '1.5rem'],
        color: 'primary'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontSize: ['1rem', '1.25rem', '1.25rem', '1.25rem']
      },
      '.text': {
        variant: 'customVariants.text'
      }
    }
  },
  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageContactUs: {
    padding: ['3rem 1rem', '', '8rem 1rem'],
    '.title': {
      variant: 'customVariants.title',
      order: '1'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      order: '2'
    },
    '.text': {
      order: '3'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsCTAActive': {
      textDecoration: 'none'
    },
    '.albumTitle': {
      padding: '0.5rem',
      backgroundColor: 'black',
      borderColor: 'black',
      ':hover': {
        backgroundColor: 'primary',
        borderColor: 'primary'
      }
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px',
        filter: 'brightness(0) invert(1)'
      }
    },

    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    backgroundColor: 'black',
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/mooning.png)',
    backgroundRepeat: 'repeat',
    backgroundSize: 'auto',
    h2: {
      textTransform: 'uppercase',
      color: 'light'
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '9rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    }
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem'],
      color: 'primary',
      marginBottom: '1.5rem',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    }
  }
}
